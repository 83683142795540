import React from 'react'
import { Box, Text } from 'grommet'
import LazyLoad from 'react-lazy-load'

import ImageLoader from './ImageLoader'

export default props => {
  const { properties, _id, image } = props.zone
  const src = image ? { jpeg: `${image}.jpg`, webp: `${image}.webp` } : { jpeg: '/website/sale.svg', webp: '/website/sale.svg' }
  return (
    <a
      className="ui-property-card ui-property-card--zone"
      href={`/search?locationId=${_id}&locationType=zone&locationName=${properties.name[props.language]}`}
    >
      <Box className="ui-property-card__box  mb-2" direction="column">
        <LazyLoad height={200}>
          <ImageLoader src={src.jpeg} webP={src.webP} alt={image.jpeg} />
        </LazyLoad>
        <Box pad="small">
          <div className="w-100 d-flex justify-content-between pb-2">
            <Text size="medium">{properties.name[props.language]}</Text>
          </div>
        </Box>
      </Box>
    </a>
  )
}
