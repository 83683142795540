import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Heading, Text } from 'grommet'
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'

import LoadingProperties from './LoadingProperties'
import PropertyCard from './PropertyCard'
import ZoneCard from './ZoneCard'
import { authFetch } from 'utils/website-utils'

import { logError } from 'utils/sentry-log'

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      properties: [],
      zones: []
    }
  }

  async componentDidMount() {
    try {
      const response = await authFetch('get', `/api/v1/home`)
      if (!response || !response.data || !response.status === 200) throw new Error('[HOME-FETCH] error')

      const { properties, zones } = response.data
      const propertyCards = properties.map(property => (
        <PropertyCard key={property._id} property={property} language={this.props.intl.locale} isSearch={true}></PropertyCard>
      ))
      const zonesWithImages = zones.filter(zone => zone.image)
      const zoneCards = zonesWithImages.map(zone => (
        <Col sm={12} md={6} lg={4} key={zone._id}>
          <ZoneCard zone={zone} language={this.props.intl.locale}></ZoneCard>
        </Col>
      ))
      this.setState({ properties: propertyCards, zones: zoneCards })
    } catch (e) {
      logError(e)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    const { properties, zones, isLoading } = this.state
    const { intl } = this.props

    return (
      <>
        {intl.locale !== 'en' && (
          <Container fluid className="pt-3 pb-4">
            <Container>
              <Row>
                <Heading level={2} className="pt-3 pb-2">
                  <FormattedMessage id="website.title"></FormattedMessage>
                </Heading>
                <Text>
                  <FormattedHTMLMessage id="website.home.text1" />
                </Text>
                <Text className="pt-3" weight="bold">
                  <FormattedMessage id="website.home.text2" />
                </Text>
                <Text className="pt-3">
                  <FormattedHTMLMessage id="website.home.text3" />
                </Text>
              </Row>
            </Container>
          </Container>
        )}

        <Container fluid className="pt-3 pb-4 bg-light">
          <Container>
            <Heading level={2} className="pt-3 pb-2">
              <FormattedMessage id="website.home.properties"></FormattedMessage>
            </Heading>
            <Row>{isLoading ? <LoadingProperties count={3}></LoadingProperties> : properties}</Row>
          </Container>
        </Container>
        <Container className="pt-3 pb-3">
          <Heading level={2} className="pt-3 pb-2">
            <FormattedMessage id="website.home.zones"></FormattedMessage>
          </Heading>
          <Row>{isLoading ? <LoadingProperties count={3}></LoadingProperties> : zones}</Row>
        </Container>
      </>
    )
  }
}

export default injectIntl(withRouter(HomePage))
